import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"
import MainLayout from "../components/layouts/main-layout"
import Img from "gatsby-image"
import "./index.scss"

const IndexPage = ({ data }) => {
  return (
    <div id={"index"}>
      <SEO title="Home" />
      <div id="tool-index">
        <div className="wrapper-title">
          <div className={"wrapper-title__inner"}>
            <Img fixed={data.file.childImageSharp.fixed} />
            <h1>Welcome on SESF tools</h1>
          </div>
        </div>
        <ul>
          <li>
            <Link to={"/signature"}>Signature</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "SESF_logo_squared.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
